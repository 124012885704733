import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCatListModelData } from 'data/models/catListModel';
import fetcher from 'utils/fetcher';

const slice = createSlice({
  name: 'app',
  initialState: {
    config: {
      status: {
        isLoading: false,
        isError: false,
      },
    },
    error: null,
    bottomSheetOpen: false,
    isCatListLoading: false,
    currentView: '',
    catId: null,
    catList: null,
    clickedFeature: null,
    isLoading: false,
    isMapLoading: false,
    yardsDialogStatus: false,
    selectedYardsCount: null,
    closeSidebar: false,
    spatialFilter: null,
    polygonFeatures: {
      type: 'FeatureCollection',
      features: [],
    },
    polygonMode: 'ViewMode',
  },
  reducers: {
    setSavedFilters: (state, action) => {
      const { filters, widgets = {} } = action.payload;
      //state.filters = filters;
      if (Object.keys(widgets).length > 0) {
        state.widgets = widgets;
      }
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setBottomSheetOpen: (state, action) => {
      state.bottomSheetOpen = action.payload;
    },
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    },
    setSelectedCatId: (state, action) => {
      state.catId = action.payload;
    },
    setClickedFeature: (state, action) => {
      state.clickedFeature = action.payload;
    },
    setCloseSidebar: (state, action) => {
      state.closeSidebar = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setYardsDialogStatus: (state, action) => {
      state.yardsDialogStatus = action.payload;
    },
    setSelectedYardsCount: (state, action) => {
      state.selectedYardsCount = action.payload;
    },
    setMapLoadingStatus: (state, action) => {
      state.isMapLoading = action.payload;
    },
    setSpatialFilter: (state, action) => {
      state.spatialFilter = action.payload;
    },
    setPolygonFeatures: (state, action) => {
      state.polygonFeatures = action.payload;
    },
    setPolygonMode: (state, action) => {
      state.polygonMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatList.pending, (state) => {
        state.isCatListLoading = true;
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCatList.fulfilled, (state, action) => {
        state.isCatListLoading = false;
        state.isLoading = false;
        state.catList = action.payload;
      })
      .addCase(fetchCatList.rejected, (state, action) => {
        state.isCatListLoading = false;
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchAppConfig.pending, (state) => {
        state.config.status.isLoading = true;
        state.config.status.isError = false;
        state.error = null;
      })
      .addCase(fetchAppConfig.fulfilled, (state, action) => {
        state.config.status.isLoading = false;
        state.config = { ...state.config, ...action.payload };
      })
      .addCase(fetchAppConfig.rejected, (state, action) => {
        state.config.isLoading = false;
        state.config.status.isError = true;
        state.error = action.error.message;
      });
  },
});

//actions
export const setConfig = (payload) => ({
  type: 'app/setConfig',
  payload,
});

export const setError = (payload) => ({ type: 'app/setError', payload });

export const setBottomSheetOpen = (payload) => ({
  type: 'app/setBottomSheetOpen',
  payload,
});

export const setCurrentView = (payload) => ({
  type: 'app/setCurrentView',
  payload,
});

export const setSelectedCatId = (payload) => ({
  type: 'app/setSelectedCatId',
  payload,
});

export const setClickedFeature = (payload) => ({
  type: 'app/setClickedFeature',
  payload,
});

export const setCloseSidebar = (payload) => ({
  type: 'app/setCloseSidebar',
  payload,
});

export const setLoading = (payload) => ({
  type: 'app/setLoading',
  payload,
});

export const setYardsDialogStatus = (payload) => ({
  type: 'app/setYardsDialogStatus',
  payload,
});

export const setSelectedYardsCount = (payload) => ({
  type: 'app/setSelectedYardsCount',
  payload,
});

export const setMapLoadingStatus = (payload) => ({
  type: 'app/setMapLoadingStatus',
  payload,
});
export const setSavedFilters = (payload) => ({
  type: 'app/setSavedFilters',
  payload,
});
export const setSpatialFilter = (payload) => ({
  type: 'app/setSpatialFilter',
  payload,
});
export const setPolygonFeatures = (payload) => ({
  type: 'app/setPolygonFeatures',
  payload,
});
export const setPolygonMode = (payload) => ({
  type: 'app/setPolygonMode',
  payload,
});
export const fetchCatList = createAsyncThunk('app/fetchCatList', async (payload) => {
  try {
    const response = await getCatListModelData(payload);
    return response;
  } catch (error) {
    throw new Error(`Failed to fetch Cat List, ${error.message}`);
  }
});

export const fetchAppConfig = createAsyncThunk('app/fetchAppConfig', async (payload) => {
  try {
    const response = await fetcher('/api/v1/app-config-data').then((res) => res.data);
    return response;
  } catch (error) {
    throw new Error(`Failed to fetch Config, ${error.message}`);
  }
});

export default slice.reducer;
