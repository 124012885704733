import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createCartoSlice } from '@carto/react-redux';
import { setDefaultCredentials } from '@deck.gl/carto';
import App from './App';
import { initialState } from 'store/initialStateSlice';
import configureAppStore from './store/store';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
import { v4 as uuidv4 } from 'uuid';

const store = configureAppStore();

store.reducerManager.add('carto', createCartoSlice(initialState));

setDefaultCredentials({ ...initialState.credentials });

function OauthProvider({ children }) {
  const { domain, scopes, audience, organizationId } = initialState.oauth;
  const protocol = window.location.protocol;
  const host = window.location.host;
  const [clientId, setClientId] = useState(null);
  const url = '/api/v1/bootstrap-config';

  useEffect(() => {
    const fetchBootstrapConfig = async () => {
      const requestId = uuidv4();
      try {
        const response = await fetch(url, {
          headers: {
            correlationId: requestId,
          },
        }).then((res) => res.json());
        setClientId(response.clientId);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBootstrapConfig();
  }, []);

  if (!initialState.oauth) {
    return children;
  }

  const onSigninCallbackfun = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  const oidcConfig = {
    authority: domain,
    client_id: clientId,
    redirect_uri: window.location.origin,
    onSigninCallback: onSigninCallbackfun,
    //postLogoutRedirectUri: window.location.origin,
    onRemoveUser: () => {
      window.location.pathname = '/login';
    },
    automaticSilentRenew: true,
    silent_redirect_uri: window.location.origin,
    userStore: new WebStorageStateStore({
      prefix: 'godseyestore',
      store: window.sessionStorage,
    }),
  };

  return <>{clientId ? <AuthProvider {...oidcConfig}>{children}</AuthProvider> : null}</>;
}

const AppProvider = (
  <Provider store={store}>
    <OauthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </OauthProvider>
  </Provider>
);

ReactDOM.render(AppProvider, document.getElementById('root'));
