import { fetchSQL } from 'utils/sqlUtils';

export async function getCatListModelData({ projectId, opts = {} }) {
  opts = {
    ...opts,
    cache: 'reload',
  };
  const query = `SELECT *
  FROM  ${projectId}.yds.catastrophes
  WHERE status = 'A' AND 
  is_active = 1 AND 
  DATE_ADD(CURRENT_DATE, INTERVAL 2 MONTH) >= loss_end_dt AND 
  loss_end_dt >= DATE_SUB(CURRENT_DATE(), INTERVAL 2 MONTH)
`;
  const data = await fetchSQL(query);
  return data;
}

export async function getCatMapCordinates({ opts = {}, catId, projectId }) {
  opts = {
    ...opts,
    cache: 'reload',
  };
  const query =
    `SELECT fclty_lng as facility_longitude,fclty_lat as facility_latitude from ${projectId}.yds.facilities WHERE fclty_id IN
  (WITH cat_facilities AS (SELECT JSON_EXTRACT_ARRAY(facilities_json, '$') AS facilities 
  FROM ${projectId}.yds.catastrophes WHERE cat_id=` +
    catId +
    ` )
  SELECT CAST(unnested_facilties AS INT64) FROM cat_facilities, UNNEST(facilities) unnested_facilties limit 1)`;

  const data = await fetchSQL(query);
  return data;
}
