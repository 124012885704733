import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { setCredentials } from '@carto/react-redux';
import { fetchAppConfig } from 'store/appSlice';

export default function GodsEyeAuthAndConfig() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { isAuthenticated, events, removeUser, user } = useAuth();

  useEffect(() => {
    const silentRenewErrorHandler = (error) => {
      console.error('Silent renewal error:');
      removeUser();
    };
    events.addSilentRenewError(silentRenewErrorHandler);

    return () => {
      events.removeSilentRenewError(silentRenewErrorHandler);
    };
  }, [events.addSilentRenewError]);

  useEffect(() => {
    const getAccessToken = async () => {
      let accessToken = auth.user?.access_token;
      if (auth.isAuthenticated) {
        dispatch(setCredentials({ accessToken }));
        try {
          dispatch(fetchAppConfig());
        } catch (error) {
          console.error('Error fetching config data:', error);
        }
      }
    };
    getAccessToken();
  }, [auth.user?.access_token, isAuthenticated, dispatch]);

  return;
}
