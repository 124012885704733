import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const LinearProgressTopLoading = styled(LinearProgress)(({ theme }) => ({
  position: 'absolute',
  top: 45,
  left: 0,
  width: '100%',
  height: theme.spacing(0.35),
  zIndex: 9999,
  '.MuiLinearProgress-bar': {
    transition: 'none',
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default function TopLoading({ style }) {
  return <LinearProgressTopLoading sx={style} color='primary' />;
}
