import React from 'react';
import { Language } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setYardsDialogStatus } from 'store/appSlice';

const YardsSelectionButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  fontWeight: 600,
  ':hover, :focus-visible ': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}));

const YardsSelection = () => {
  const dispatch = useDispatch();
  const selectedYardsCount = useSelector((state) => state.app?.selectedYardsCount);
  const currentView = useSelector((state) => state.app.currentView);

  return (
    <>
      {currentView ? (
        <YardsSelectionButton
          aria-label='Yard Selection'
          startIcon={<Language />}
          variant='contained'
          onClick={() => dispatch(setYardsDialogStatus(true))}
        >
          {' '}
          Yards
          {selectedYardsCount ? (
            <span style={{ marginLeft: '2px' }}>{'(' + selectedYardsCount + ')'}</span>
          ) : null}
        </YardsSelectionButton>
      ) : null}
    </>
  );
};

export default YardsSelection;
