import fetcher from './fetcher';

export const generateWhereClause = (filters = {}, spatialFilter) => {
  let columns = [];
  let whereClauses = [];
  if (spatialFilter) {
    let spatialCondition = generateSpatialCondition(spatialFilter);
    if (spatialCondition !== '') {
      whereClauses.push(spatialCondition);
    }
  }
  if (filters !== null || undefined) {
    columns = Object.keys(filters);
  }
  if (columns.length > 0) {
    for (let column of columns) {
      let columnValues = filters[column];
      if (Array.isArray(columnValues) && columnValues.length > 0) {
        const formattedValues = columnValues.map((value) => {
          if (typeof value === 'string') {
            return `'${value}'`;
          }
          return value;
        });
        if (column == 'driver_eta_and_confidence') {
          const whereClause = `${column} IN (${formattedValues.join(
            ', '
          )}) and acknowledged_in_transit_flg = 1`;
          whereClauses.push(whereClause);
        } else {
          const whereClause = `${column} IN (${formattedValues.join(', ')})`;
          whereClauses.push(whereClause);
        }
      }
    }
    if (whereClauses.length > 0) {
      return 'WHERE ' + whereClauses.join(' AND ');
    }
  }
  return whereClauses.length > 0 ? 'WHERE ' + whereClauses.join(' AND ') : '';
};

export function generateSelect(columns, count = false) {
  if (Array.isArray(columns) && columns.length > 0) {
    return count
      ? `SELECT ${columns.join(', ')}, COUNT(*) as count`
      : `SELECT ${columns.join(', ')}`;
  } else {
    // Default behavior when no specific columns are provided
    return count ? 'SELECT COUNT(*) as total' : 'SELECT *';
  }
}

export function generateOrderBy(orderBy) {
  if (orderBy && typeof orderBy === 'string') {
    return `ORDER BY ${orderBy}`;
  }
  return '';
}

export function generatePagination(limit, offset) {
  const limitClause = limit ? `LIMIT ${limit}` : '';
  const offsetClause = offset ? `OFFSET ${offset}` : '';
  return `${limitClause} ${offsetClause}`;
}

export function generateSpatialCondition(spatialFilter) {
  let obj = {
    type: spatialFilter?.type,
    coordinates: spatialFilter?.coordinates,
  };
  if (Array.isArray(obj?.coordinates) && obj?.coordinates.length > 0) {
    if (obj.coordinates[0][1][0] - obj.coordinates[0][0][0] > 360) {
      return '';
    } else {
      return `ST_INTERSECTS(${
        spatialFilter?.geomColumn || 'geom'
      },ST_GEOGFROMGEOJSON('${JSON.stringify(obj)}'))`;
    }
  }
  return '';
}

export function generateGroupBy(groupBy) {
  if (groupBy && Array.isArray(groupBy) && groupBy.length > 0) {
    return `GROUP BY ${groupBy.join(', ')}`;
  }
  return '';
}

export function generateHavingClause(having) {
  if (having && typeof having === 'string') {
    return `HAVING ${having}`;
  }
  return '';
}

/**
 * Returns a whereClause as string applied for specfic columns in the filters
 *
 * @param {object} filters Has filters with column name as keys
 * @param {Array} columns Contains the columns for which where clause is generated .
 * @return {string} Generated where clause.
 *
 */
export function applyWhereClauseFor(filters, columns) {
  if (
    filters &&
    Object.keys(filters).length > 0 &&
    columns &&
    Array.isArray(columns) &&
    columns.length > 0
  ) {
    const finalFilters = columns.reduce((acc, curr) => {
      if (filters[curr]) {
        acc[curr] = filters[curr];
      }
      return acc;
    }, {});

    const whereClauses = generateWhereClause(finalFilters);
    return whereClauses ? whereClauses : 'where';
  }
  return 'where';
}

/**
 * Returns a sql query with the params received.
 *
 * @param {object} params Includes all required parameters for generating sql query .
 * @param {boolean} count Flag for count function for query .
 * @return {string} sql query with received params.
 * example: 
 *    const params = {
        columns: [],
        filters: {},
        havingCheck: ""
        orderBy: '',//is_cleared DESC
        limit: null,
        offset: null,
        innerQueryFlag: false,
        table: {
          innerQuery: '',
          tableName: '',
        },
        spatialFilter: [],
        excludedWhereClause: []
      };
 */
export function generateSQLQuery(params, count = false) {
  const {
    columns,
    filters,
    orderBy,
    limit = null,
    offset = null,
    table: { innerQuery = '', tableName = '' },
    spatialFilter,
    queryType,
    excludedWhereClause,
    havingCheck,
  } = params;
  let newFilters = { ...filters };
  excludedWhereClause?.forEach((element) => {
    delete newFilters[element];
  });
  const selectClause = generateSelect(columns, count);
  const whereClause = generateWhereClause(newFilters, spatialFilter);
  const groupByClause = generateGroupBy(columns);
  const havingClause = generateHavingClause(havingCheck);
  const orderByClause = generateOrderBy(orderBy);
  const paginationClause = generatePagination(limit, offset);

  const sqlQuery =
    queryType === 'innerQuery' && innerQuery
      ? `${selectClause} FROM (${innerQuery}) ${whereClause} ${groupByClause} ${havingClause} ${orderByClause} ${paginationClause};`
      : `${selectClause} FROM ${tableName} ${whereClause} ${groupByClause} ${havingClause} ${orderByClause} ${paginationClause};`;

  return sqlQuery.trim();
}

export async function fetchSQL(query, signal = null) {
  const url = `/api/v1/query?sql_query=${encodeURIComponent(query)}`;
  const response = await fetcher(url, {
    signal,
  });
  const jsonData = response.data;
  return jsonData.data;
}
