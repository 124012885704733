(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@mui/material"), require("react"), require("@mui/system"), require("@mui/icons-material"), require("echarts-for-react"), require("@carto/react-core"), require("@mui/icons-material/KeyboardArrowDown"), require("@mui/material/styles"));
	else if(typeof define === 'function' && define.amd)
		define(["@mui/material", "react", "@mui/system", "@mui/icons-material", "echarts-for-react", "@carto/react-core", "@mui/icons-material/KeyboardArrowDown", "@mui/material/styles"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactUi"] = factory(require("@mui/material"), require("react"), require("@mui/system"), require("@mui/icons-material"), require("echarts-for-react"), require("@carto/react-core"), require("@mui/icons-material/KeyboardArrowDown"), require("@mui/material/styles"));
	else
		root["cartoReactUi"] = factory(root["@mui/material"], root["react"], root["@mui/system"], root["@mui/icons-material"], root["echarts-for-react"], root["@carto/react-core"], root["@mui/icons-material/KeyboardArrowDown"], root["@mui/material/styles"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__901__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__719__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__981__, __WEBPACK_EXTERNAL_MODULE__485__, __WEBPACK_EXTERNAL_MODULE__62__, __WEBPACK_EXTERNAL_MODULE__655__) => {
return 