import { useState } from 'react';
import {
  AppBar,
  Divider,
  Grid,
  Toolbar,
  Link,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  styled,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ReactComponent as CopartLogo } from 'assets/img/copart-logo.svg';
import { ROUTE_PATHS } from 'routes';
import Breadcrumb from './BreadCrumb';
import YardsSelection from './yardsSelection/YardsSelection';
import { useAuth } from 'react-oidc-context';
import jwt from 'jwt-decode';
import { setCredentials } from '@carto/react-redux';
import { useDispatch } from 'react-redux';

const LinkTitle = styled(Link)(({ theme }) => ({
  '& h1': {
    display: 'flex',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.white,

    '& strong': {
      marginRight: theme.spacing(0.5),
    },

    '& svg': {
      height: `${theme.typography.subtitle1.lineHeight}em`,
      marginRight: theme.spacing(1.5),
      width: 'auto',
      verticalAlign: 'bottom',
    },
  },
}));

const AppBarHeader = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  zIndex: theme.zIndex.modal + 1,
  overflow: 'hidden',
  background: theme.palette.primary.main,
  position: 'sticky',
  top: 0,
}));

export default function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <AppBarHeader position='static'>
        <Toolbar variant='dense'>
          {isMobile && <Mobile />}
          {!isMobile && <Desktop />}
        </Toolbar>
      </AppBarHeader>
    </>
  );
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
}));

function Desktop() {
  return (
    <>
      <LinkTitle component={NavLink} to={ROUTE_PATHS.DEFAULT} underline='none'>
        <Typography component='h1' variant='subtitle1' noWrap>
          <CopartLogo />
          <AppName />
        </Typography>
      </LinkTitle>
      <StyledDivider orientation='vertical' light flexItem />
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Breadcrumb />
        </Grid>
        <Grid
          container
          direction={'row'}
          justifyContent='left'
          alignItems='center'
          columnGap={2}
          sx={{ width: 'fit-content' }}
        >
          <Grid item>
            <YardsSelection />
          </Grid>
          <Grid item>
            <UserMenu />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function Mobile() {
  return (
    <>
      <LinkTitle component={NavLink} to={ROUTE_PATHS.DEFAULT}>
        <Typography component='h1' variant='subtitle1' noWrap>
          <>
            <CopartLogo />
            <Divider orientation='vertical' light />
          </>
          <AppName />
        </Typography>
      </LinkTitle>
      <Grid container item xs justifyContent='flex-end'>
        <Grid item>
          <YardsSelection />
        </Grid>
        <Grid item>
          <UserMenu />
        </Grid>
      </Grid>
    </>
  );
}

function AppName() {
  return (
    <>
      <strong>Command Center</strong>
    </>
  );
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  cursor: 'pointer',
  width: theme.spacing(4.5),
  height: theme.spacing(4.5),
  marginLeft: theme.spacing(1),
}));

function UserMenu() {
  const dispatch = useDispatch();
  const { user, removeUser } = useAuth();

  const userEmail = user ? jwt(user.access_token).email : '';
  const [anchorEl, setAnchorEl] = useState(null);

  if (!user) {
    return null;
  }

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutWithRedirect = () => {
    dispatch(setCredentials({ accessToken: null }));
    removeUser();
    handleClose();
  };

  return (
    <>
      <Link
        edge='end'
        aria-label='account of current user'
        aria-controls='menu-login'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
      >
        <Grid container alignItems='center' item wrap='nowrap'>
          {
            <Typography
              variant='caption'
              color='inherit'
              noWrap
              sx={{ display: { md: 'block', xs: 'none' } }}
            >
              {userEmail}
            </Typography>
          }
          <StyledAvatar src={user.picture} />
        </Grid>
      </Link>
      <Menu
        id='menu-login'
        anchorEl={anchorEl}
        getcontentanchorel={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={logoutWithRedirect}>Logout</MenuItem>
      </Menu>
    </>
  );
}
