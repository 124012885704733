import { Box, Button, Typography, styled } from '@mui/material';
import { OpenInNewRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router';

const BoxSummaryCard = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,

  '& *': {
    fontFamily: theme.typography.fontFamily,
  },
  background: '#fff',
  boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
  borderRadius: '4px',
  height: '100%',
}));

const TypographyTitle = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '1.5',
  letterSpacing: '0.1px',
  color: '#2C3032',
  marginRight: '22px',
}));

const TypographyButtonName = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: '#fff',
}));

const DivHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 10px 10px 20px',
  height: 'inherit',
}));

const ButtonOpenButton = styled(Button)(({ theme }) => ({
  padding: '5px 20px',
  backgroundColor: '#1D5AB9',

  boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',

  borderRadius: '4px',
  color: '#fff',

  '&:hover': {
    backgroundColor: '#3273d9',
  },
}));

const TypographyCreatedDate = styled(Typography)(({ theme }) => ({
  color: '#605E5C',
  fontWeight: '500',
  alignSelf: 'baseline',
}));

export default function SummaryCard({
  size = '700px',
  dataJson,
  title,
  catType,
  createdDate = null,
  icon,
  link = null,
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <BoxSummaryCard>
      <DivHeader>
        <Box sx={{ display: 'flex', columnGap: '10px', alignItems: 'center' }}>
          {icon}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TypographyTitle variant={'body1'}>{title}</TypographyTitle>
            <TypographyCreatedDate variant={'body1'} style={{ fontSize: '14px' }}>
              {catType}
            </TypographyCreatedDate>
          </div>
          {createdDate ? (
            <TypographyCreatedDate variant={'body1'}>
              {`Created ${createdDate}`}
            </TypographyCreatedDate>
          ) : null}
        </Box>
        {link ? (
          <ButtonOpenButton
            variant='contained'
            referrerPolicy='no-referrer'
            startIcon={<OpenInNewRounded />}
            onClick={handleClick}
          >
            <TypographyButtonName>Open</TypographyButtonName>
          </ButtonOpenButton>
        ) : null}
      </DivHeader>
    </BoxSummaryCard>
  );
}
