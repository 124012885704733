import axios from 'axios';
import { initialState } from 'store/initialStateSlice';
import { getSessionItemsWithPrefix } from './storage';
import { v4 as uuidv4 } from 'uuid';
import { User } from 'oidc-client-ts';

const fetcher = axios.create();

function getUser() {
  const storage = getSessionItemsWithPrefix(
    `godseyestoreuser:${initialState.oauth.domain}:`
  );
  if (!storage) {
    return null;
  }
  return User.fromStorageString(storage);
}

fetcher.interceptors.request.use((config) => {
  const user = getUser();
  const authToken = user?.access_token;
  const { headers } = config;
  const requestId = uuidv4();
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }
  headers.correlationID = requestId;
  return config;
});

fetcher.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response) {
      const error = err.response;
      return Promise.reject(error);
    }
    return Promise.reject(err);
  }
);

export default fetcher;
