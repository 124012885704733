import { Breadcrumbs, styled } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
const StyledLink = styled(Link)(({ theme }) => ({
  color: 'rgb(255, 255, 255, 0.7)',
  textTransform: 'capitalize',
  fontSize: '14px',
  fontWeight: '600',
  fontFamily: theme.typography.fontFamily,
  textDecoration: 'none',

  '&:hover': {
    color: 'rgb(255, 255, 255, 1)',
  },
}));

const SpanSeperator = styled('span')(({ theme }) => ({
  color: 'rgb(255, 255, 255, 0.7)',
  fontSize: '16px',
  '> .MuiSvgIcon-root': {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
}));

function Breadcrumb() {
  const location = useLocation();
  const seperator = (
    <SpanSeperator>
      <ArrowForwardIosRoundedIcon />
    </SpanSeperator>
  );
  const pathSegments = location.pathname.split('/').filter((segment) => segment !== '');
  const breadcrumbs = pathSegments.map((segment, index) => {
    const url = `/${pathSegments.slice(0, index + 1).join('/')}`;
    const isLastSegment = index === pathSegments.length - 1;
    return (
      <StyledLink
        key={segment}
        to={url}
        style={isLastSegment ? { color: 'rgb(255, 255, 255, 1)' } : {}}
      >
        {Number(segment) ? `#${segment}` : segment}
      </StyledLink>
    );
  });
  breadcrumbs.unshift(
    <StyledLink
      key='home'
      to={'/'}
      style={breadcrumbs.length === 0 ? { color: 'rgb(255, 255, 255, 1)' } : {}}
    >
      {'Home'}
    </StyledLink>
  );
  return (
    <Breadcrumbs separator={seperator} aria-label='breadcrumb'>
      {breadcrumbs.length > 0 && breadcrumbs}
    </Breadcrumbs>
  );
}

export default Breadcrumb;
