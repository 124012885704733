import deepmerge from 'deepmerge';
import { createTheme } from '@mui/material';
import { cartoThemeOptions } from '@carto/react-ui';

const customTheme = {
  palette: {
    primary: {
      main: '#1D5AB9', // Copart Blue
    },
  },
};

const theme = createTheme(deepmerge(cartoThemeOptions, customTheme));

export default theme;
