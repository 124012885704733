import { lazy } from 'react';
import ProtectedRoute from 'components/common/ProtectedRoute';
import DefaultView from 'components/common/DefaultView';
import { Navigate } from 'react-router';
import Home from 'components/views/Home.js';

const Main = lazy(() => import(/* webpackPrefetch: true */ 'components/views/main/Main'));
const NotFound = lazy(() => import('components/views/NotFound'));
const Login = lazy(() => import('components/views/Login'));
const MetricsInfo = lazy(() => import('components/views/DashboardNew'));
// [hygen] Import views

export const ROUTE_PATHS = {
  LOGIN: '/login',
  DEFAULT: '/',
  NOT_FOUND: '/404',
  DASHBOARD: '/dashboard',
  GLOBALVIEW: '/globalview',
  CATVIEW: '/cat/:catId',
  CAT: '/cat',
  // [hygen] Add path routes
};

const routes = [
  {
    path: ROUTE_PATHS.DEFAULT,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Home />
        </DefaultView>
      </ProtectedRoute>
    ),
    children: [{ path: ROUTE_PATHS.DASHBOARD, element: <Home /> }],
  },
  ,
  {
    path: ROUTE_PATHS.GLOBALVIEW,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Main />
        </DefaultView>
      </ProtectedRoute>
    ),
    children: [
      { path: '', element: <MetricsInfo /> },
      // [hygen] Add routes
    ],
  },
  {
    path: ROUTE_PATHS.CAT,
    element: <Navigate to={'/'} replace />,
  },
  {
    path: ROUTE_PATHS.CATVIEW,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Main />
        </DefaultView>
      </ProtectedRoute>
    ),
    children: [{ path: '', element: <MetricsInfo /> }],
  },
  { path: ROUTE_PATHS.LOGIN, element: <Login /> },
  {
    path: '*',
    element: (
      <DefaultView>
        <NotFound />
      </DefaultView>
    ),
  },
];

export default routes;
