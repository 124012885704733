//Get first found localStorage item with prefix
export function getLocalItemsWithPrefix(prefix) {
  const matchingItems = {};

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (key.startsWith(prefix)) {
      matchingItems[key] = localStorage.getItem(key);
      const json = matchingItems?.[key] ? matchingItems[key] : '';
      return json;
    }
  }
  return matchingItems;
}

//Get first found sessionStorage item with prefix
export function getSessionItemsWithPrefix(prefix) {
  const matchingItems = {};

  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (key.startsWith(prefix)) {
      matchingItems[key] = sessionStorage.getItem(key);
      const json = matchingItems?.[key] ? matchingItems[key] : '';
      return json;
    }
  }
  return matchingItems;
}
