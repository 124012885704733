import { createSlice } from '@reduxjs/toolkit';

const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    triggerredWidgetId: undefined,
    globalFilters: {},
    lotsLayer: {
      trp_type_cd: ['P'],
    },
    trucksLayer: {
      //  is_active_device: [1]
    },
  },
  reducers: {
    setFilters: (state, action) => {
      const { layerId, filters, widgetOwner } = action.payload;
      state.triggerredWidgetId = widgetOwner;
      if (state[layerId]) {
        state[layerId] = { ...state[layerId], ...filters };
      } else {
        state[layerId] = filters;
      }
    },
    removeFilters: (state, action) => {
      const { layerId, column } = action.payload;
      state.triggerredWidgetId = undefined;
      const layerFilters = state[layerId];
      delete layerFilters[column];
    },
    setGlobalFilters: (state, action) => {
      const { filterId, newFilters } = action.payload;
      state.triggerredWidgetId = filterId;
      if (state.globalFilters[filterId] && Array.isArray(newFilters)) {
        state.globalFilters = {
          ...state.globalFilters,
          [filterId]: newFilters,
        };
      } else {
        state.globalFilters = {
          ...state.globalFilters,
          [filterId]: newFilters,
        };
      }
    },
    removeGlobalFilters: (state, action) => {
      const { filterId } = action.payload;
      if (state.globalFilters[filterId]) {
        delete state.globalFilters[filterId];
      }
    },
  },
});

//actions
export const setFilters = (layerId, filters, widgetOwner) => ({
  type: 'filter/setFilters',
  payload: { layerId, filters, widgetOwner },
});

export const removeFilters = (layerId, column) => ({
  type: 'filter/removeFilters',
  payload: { layerId, column },
});

export const setGlobalFilters = (filterId, newFilters) => ({
  type: 'filter/setGlobalFilters',
  payload: { filterId, newFilters },
});

export const removeGlobalFilters = (filterId) => ({
  type: 'filter/removeGlobalFilters',
  payload: { filterId },
});

export default filterSlice.reducer;
