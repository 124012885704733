import { styled } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import SummaryCard from 'components/common/SummaryCard';
import { useEffect } from 'react';
import { fetchCatList } from 'store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';
import { ReactComponent as GlobeIcon } from 'assets/img/globe-icon.svg';
import { ReactComponent as CatLogo } from 'assets/img/cat-logo.svg';
import { formatDate } from 'utils/date';
import TopLoading from 'components/common/TopLoading';
import jwt from 'jwt-decode';

const GridHome = styled(Grid)(({ theme }) => ({
  padding: '48px 64px',
  [theme.breakpoints.down('lg')]: {
    padding: '40px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '32px',
  },
}));

const TypographyGreetingHeader = styled(Typography)(() => ({
  fontFamily: 'Inter, sans-serif',
  fontSize: '30px',
  fontWeight: '600',
  lineHeight: '28px',
  letterSpacing: '0.1px',
  textAlign: 'left',
  textTransform: 'capitalize',
}));

export default function Home() {
  const config = useSelector((state) => state.app?.config);
  const { user } = useAuth();
  const username = jwt(user?.access_token)?.email
    ? jwt(user.access_token).email.split('.')[0]
    : '';
  const dispatch = useDispatch();
  const catList = useSelector((state) => state.app?.catList);
  const projectId = config?.projectId;
  const isLoading = useSelector((state) => state.app?.isLoading);

  useEffect(() => {
    if (projectId) dispatch(fetchCatList({ projectId }));
  }, [config]);

  return (
    <>
      {isLoading && <TopLoading />}
      <GridHome container spacing={4}>
        <Grid item xs={12} md={12} lg={12}>
          <TypographyGreetingHeader>Welcome {username}</TypographyGreetingHeader>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <SummaryCard icon={<GlobeIcon />} title='Global View' link='/globalview' />
        </Grid>
        {catList &&
          catList.map((cat, index) => (
            <Grid item key={index} xs={12} md={6} lg={6}>
              <SummaryCard
                key={index}
                icon={<CatLogo />}
                title={(cat?.cat_nm).toUpperCase()}
                catType={cat?.cat_type}
                createdDate={formatDate(cat?.crt_dt.value)}
                link={`/cat/${cat?.cat_id}`}
              />
            </Grid>
          ))}
      </GridHome>
    </>
  );
}
