export const options = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

export const formatDate = (date) =>
  new Intl.DateTimeFormat('en-US', options).format(new Date(date));

export const convertDate = (date) => {
  const dateObject = new Date(date);
  const month = dateObject.toLocaleString('en-US', { month: 'long' });
  const day = dateObject.toLocaleString('en-US', { day: 'numeric' });
  const year = dateObject.toLocaleString('en-US', { year: 'numeric' });
  const time = dateObject.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  const formattedDate = `${month} ${day}, ${year}  ${time}`;
  return formattedDate;
};

export const getDateTimeDifference = (dateString1, dateString2) => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  const timeDifference = date2 - date1;
  const seconds = timeDifference / 1000;
  const minutes = seconds / 60;
  const hourDiff = minutes / 60;
  const dayDiff = hourDiff / 24;
  return { dayDiff, hourDiff };
};
