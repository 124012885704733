import { createSlice } from '@reduxjs/toolkit';

const widgetSlice = createSlice({
  name: 'widget',
  initialState: {
    trucksLayer: {
      cprt_vendor_type_enriched: {
        id: 'cprt_vendor_type_enriched',
        default: false,
        visible: true,
        basefilter: true,
        label: 'Driver Type',
      },
      is_cat: {
        id: 'is_cat',
        visible: true,
        default: false,
        basefilter: true,
        label: 'Association Type',
      },
      status: {
        id: 'status',
        visible: true,
        default: false,
        basefilter: true,
        label: 'App Status',
      },
      subtype: {
        id: 'subtype',
        visible: false,
        default: false,
        basefilter: false,
        label: 'Truck Type',
      },
      driver_status: {
        id: 'driver_status',
        visible: false,
        default: false,
        basefilter: false,
        label: 'CAT Driver Status',
      },
      intransit: {
        id: 'intransit',
        visible: false,
        default: false,
        basefilter: false,
        label: 'Estimated Arrival',
      },
      truck_in_box: {
        id: 'truck_in_box',
        visible: false,
        default: false,
        basefilter: false,
        label: 'Truck-in-a-box',
      },
    },
    lotsLayer: {
      is_cleared: {
        id: 'is_cleared',
        default: false,
        visible: true,
        basefilter: true,
        label: 'Clearing Status',
      },
      seller_company: {
        id: 'seller_company',
        visible: false,
        default: false,
        basefilter: false,
        label: 'Seller Name',
      },
      is_cat: {
        id: 'is_cat',
        visible: true,
        default: false,
        basefilter: true,
        label: 'Association Type',
      },
      // due_date: {
      //   id: 'due_date',
      //   visible: true,
      //   default: true,
      //   basefilter: false
      // }
    },
    yardsLayer: {
      yardType: {
        id: 'yardType',
        visible: true,
        default: false,
        basefilter: true,
        label: 'Facility Type',
      },
    },
    loadersLayer: {
      equipmentType: {
        id: 'equipmentType',
        visible: true,
        default: false,
        basefilter: true,
        label: 'Intransit Loaders',
      },
    },
  },
  reducers: {
    setDataFilterStatus: (state, action) => {
      const { layerId, filterKey, checked } = action.payload;
      state[layerId][filterKey] = {
        ...state[layerId][filterKey],
        visible: checked,
      };
    },
  },
});

//actions
export const setDataFilterStatus = (layerId, filterKey, checked) => ({
  type: 'widget/setDataFilterStatus',
  payload: { layerId, filterKey, checked },
});

export default widgetSlice.reducer;
